<template>
  <div>
    <Companies v-if="user.is_admin" />
    <Balance v-else />
  </div>
</template>


<script>

import Balance from './Balance.vue'
import Companies from './Companies.vue'

import {mapGetters} from 'vuex'

export default {
  name: 'Main',
  components: {
    Balance, Companies
  },
  computed: {
    ...mapGetters(['user', ]),
  },
}
</script>