<template>
  <v-sheet outlined rounded min-height="100%">
    <!-- {{ companies }} -->
    <Loader v-if="loading" />
    <RefNavigator v-else 
      :items="filteredCompanies" 
      :headers="headers" 
      :hasAdditionBtn="true" 
      :hasSearchTextField="true" 
      :hasShowRemovedBtn="true" 
      :hasEditBtns="true"
      @click:row="onClickRowEvent"
      @click:add="onClickAdd"
      @delete="onDelete"
      @restore="onRestore"
    />
    <router-view v-if="!loading"/>
  </v-sheet>
</template>

<script>
  import {mapGetters} from 'vuex'
  import RefNavigator from '../components/RefNavigator.vue'
  import Loader from '../components/ui/Loader.vue'

  export default {
    name: 'Companies',
    components: {
      RefNavigator,
      Loader
    },
    data() {
      return {
        loading : true,
      }
    },
    async mounted() {
      await this.$store.dispatch('load_companies')
      this.loading = false
    },
    methods: {
      onClickRowEvent(payload) {
        this.$router.push('/companies/' + payload.id)
      },
      onClickAdd() {
        this.$router.push('/companies/new')
      },
      onDelete(item) {
        this.$store.dispatch('delete_company', item)
      },
      onRestore(item) {
        this.$store.dispatch('restore_company', item)
      },
    },
    computed: {
      ...mapGetters(['companies', ]),
      headers() {
        return [
          {
            text  : '#',
            align : 'start',
            value : 'id'
          },
          {
            text  : 'Название',
            value : 'name'
          },
          {
            text  : 'Ссылка',
            value : 'link'
          },
          {
            text  : 'Тариф',
            value : 'tariff'
          },
          {
            text  : 'Баланс',
            value : 'balance'
          },
          {
            text  : 'id создателя',
            value : 'creator_id'
          }
        ]
      },
      filteredCompanies() {
        let result = [...this.companies]
        result.map(elem => {
          elem.balance = Number(elem.balance).toFixed(2)
          elem.tariff = Number(elem.tariff).toFixed(2)
          return elem
        })
        return result
      }
    }
  }
</script>