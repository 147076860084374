<template>
  <div>
    <v-card>
      <v-card-title>Баланс:</v-card-title>
      <v-card-text v-if="loading">
        <Loader />  
      </v-card-text>    
      <v-card-text v-else>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>Текущий баланс компании</v-card-title>
                <v-card-subtitle>"{{ current_company.name }}"</v-card-subtitle>
                <v-card-text>
                  <span class="balance-font">{{ current_company.balance.toFixed(2) }} руб.</span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-card-title>Текущий тариф компании</v-card-title>
                <v-card-subtitle>"{{ current_company.name }}"</v-card-subtitle>
                <v-card-text>
                  <span class="balance-font">{{ current_company.tariff.toFixed(2) }} руб.</span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>    
    </v-card>
    <v-card class="mt-2">
      <v-card-title>Запрос на пополнение счета</v-card-title>
      <v-form @submit.prevent="billRequest" lazy-validation>
        <v-card-text>
          <span class="request-font">Просим выставить счет на</span>
          <TextField v-model="sum" type="number" required hide-spin-buttons label="Сумма счета" :rules="[rules.required, rules.sum_validator]" hint="Сумма должна быть больше 500 руб."/>
          <span class="request-font">Счет прислать на email:</span>
          <TextField v-model="email" label="Email" required :rules="[rules.required]"/>
          <v-alert type="success" v-if="message_show">Сообщение отправлено, ожидайте счет.</v-alert>
          <v-alert type="error" v-if="error.state">{{ error.msg }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn :loading="sended" type="submit" class="success" :disabled="!isValid">Отправить запрос</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
  
</template>

<style scoped>
.balance-font {
  font-size: 32px;
  font-weight: 600;
  color: green;
}
.request-font {
  font-size: 18px;
  font-weight: 400;
  color: black;
}
</style>


<script>

import Loader from '../components/ui/Loader.vue'
import TextField from '../components/ui/TextField.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Balance',
  components: {
    Loader, TextField,
  },
  data: () => ({
    loading: true,
    current_company: {},
    email : '',
    sum: 500,
    sended: false,
    message_show: false,
    error: {
      state: false,
      msg: ''
    },
  }),
  async mounted() {
    this.current_company = await this.$store.dispatch('get_user_company')
    this.email = this.user.email
    this.loading = false
  },
  computed: {
    ...mapGetters(['user', 'company']),
    rules() {
      return {
        required: value => !!value || 'Необходимо заполнить',
        sum_validator: value => Number(value) < 500 ? 'Сумма должна быть больше 500 руб.' : true
      }
    },

    isValid() {
      if (!!this.sum == false || Number(this.sum) < 500 || !!this.email == false) {
        return false
      }
      return true
    }
  },
  methods: {
    async billRequest() {
      this.sended = true
      try {
        await this.$store.dispatch('bill_request', {
          email: this.email,
          sum: this.sum
        })
        this.sended = false
        this.message_show = true
        setTimeout(() => this.message_show = false, 10000)
      } catch(e) {
        console.error(e)
        this.error.state = true
        this.error.msg = e.message
        setTimeout(() => this.error.state = false, 10000)
      }      
      this.sum = 500
      this.sended = false
    }
  }
}
</script>